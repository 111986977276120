<template>
  <b-container>
    <b-row>
      <b-col cols="12"
        ><h1>Serienummer: {{ $route.params.serialid }}</h1>
        <hr />
      </b-col>
      <b-col md="6" xs="12">
        <item-info v-if="itemID" :itemid="itemID" />
      </b-col>
      <b-col md="6" xs="12">
        <serial-info :serial="serial" v-if="serial" />
      </b-col>
      <b-col>
        <hr />
        <serial-service-info v-if="serialID" :serialid="serialID" />
        <hr />
        <serial-attachments :serialid="serialID" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'

import SerialAttachments from './SerialAttachments.vue'

import ItemInfo from '@/components/item/ItemInfo.vue'
import SerialInfo from '@/components/serial/SerialInfo.vue'
import SerialServiceInfo from '@/components/serial/SerialServiceInfo.vue'
import { getRentedSerial } from '@/services/CustomerSerialService'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ItemInfo,
    SerialInfo,
    SerialServiceInfo,
    SerialAttachments
  },
  data: function() {
    return {
      serial: {}
    }
  },
  computed: {
    serialID() {
      return this.$route.params.serialid
    },
    itemID() {
      return this.serial?.ItemID?.Key ?? null
    }
  },
  created() {
    this.getSerial()
  },
  methods: {
    async getSerial() {
      const result = await getRentedSerial({
        serialID: this.serialID,
        customerID: this.$store.state.customerID
      })
      this.serial = result
    }
  }
}
</script>

<style></style>
